
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Settings',
  components: {
  },
  setup() {
    const browseModeViewTitleShowRadio = ref(localStorage.getItem('browseModeViewTitleShow'))
    const browseModeEditingTitleShowRadio = ref(
        localStorage.getItem('browseModeEditingTitleShow'))
    const folderModeViewTitleShowRadio = ref(localStorage.getItem('folderModeViewTitleShow'))
    const folderModeEditingTitleShowRadio = ref(
        localStorage.getItem('folderModeEditingTitleShow'))

    const onBrowseModeViewTitleShowRadioChange = (value: string) => {
      browseModeViewTitleShowRadio.value = value
      localStorage.setItem("browseModeViewTitleShow", value)
    }

    const onBrowseModeEditingTitleShowRadioChange = (value: string) => {
      browseModeEditingTitleShowRadio.value = value
      localStorage.setItem("browseModeEditingTitleShow", value)
    }

    const onFolderModeViewTitleShowRadioChange = (value: string) => {
      folderModeViewTitleShowRadio.value = value
      localStorage.setItem("folderModeViewTitleShow", value)
    }

    const onFolderModeEditingTitleShowRadioChange = (value: string) => {
      folderModeEditingTitleShowRadio.value = value
      localStorage.setItem("folderModeEditingTitleShow", value)
    }

    return {
      browseModeViewTitleShowRadio,
      browseModeEditingTitleShowRadio,
      folderModeViewTitleShowRadio,
      folderModeEditingTitleShowRadio,
      onBrowseModeViewTitleShowRadioChange,
      onBrowseModeEditingTitleShowRadioChange,
      onFolderModeViewTitleShowRadioChange,
      onFolderModeEditingTitleShowRadioChange
    }
  }
});
